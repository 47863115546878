import en from '@/locales/en.json'
import fr from '@/locales/fr.json'
import sv from '@/locales/sv.json'
import de from '@/locales/de.json'
import da from '@/locales/da.json'
import nl from '@/locales/nl.json'
import no from '@/locales/no.json'

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    messages: {
        en,
        fr,
        sv,
        de,
        da,
        nl,
        no
    }
}))