const editProfile: any = async (body: Object | any) => {

  if(body.profile_image != null){
    await uploadImage(body.profile_image).then((response: any) => {
      if(response == null) return
       // Add profile image param to the body
       body.profile_image = response.key
       // Get content file's extension
       body.extension = response.extension
    })
  }

  const { data: user, status, error } = await dataFetch("/user",
    {
      method: "post",
      body: body
    }
  );

  let errorMessage;
  if (status._value == "error") errorMessage = error._object[error._key].data;

  return { user, status, errorMessage };
};

const changePassword : any = async (body: Object) => {

  const { data, status, error } = await dataFetch("/user/change-password",
    {
      method: "post",
      body: body
    }
  );

  let errorMessage;
  if (status._value == "error") {  
    errorMessage = error._object[error._key].data;
  } else {
    const authStore: any = useAuthStore()
    authStore.user.change_password = false;
  }

  return { data, status, errorMessage };
};

const checkUserLanguage = async (userLanguage: string = 'en') => {
  // Check language cookie
  const currentLanguageFromCookie = getCookie("i18n_redirected");
  // Get the locale switch function
  const switchLocalePath = useSwitchLocalePath();
  let newPath;
  
  // Convert legacy language codes
  if(!userLanguage && (currentLanguageFromCookie === "ch" || currentLanguageFromCookie === "dk")) {
    if (currentLanguageFromCookie === "ch") {
      deleteCookie("i18n_redirected");
      setCookie("i18n_redirected", "sv");
  
      newPath = switchLocalePath("sv");
    } else if (currentLanguageFromCookie === "dk") {
      deleteCookie("i18n_redirected");
      setCookie("i18n_redirected", "da");
  
      newPath = switchLocalePath("da");
    }
  } else if(!userLanguage && currentLanguageFromCookie) {
    return
  } else if (userLanguage != currentLanguageFromCookie || !currentLanguageFromCookie) {
    // Delete the cookie first
    deleteCookie("i18n_redirected");
    newPath = switchLocalePath(userLanguage);
  }

  if(newPath) {
    // Force a hard refresh when changing the language
    return navigateTo(newPath, {
      redirectCode: 301,
      external: true,
    });
  }
}

export { editProfile, changePassword, checkUserLanguage };
