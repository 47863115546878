import { default as indeximyHB8hHuEMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue?macro=true";
import { default as indexmZ0TLnm6WUMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue?macro=true";
import { default as indexmQebns9TshMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue?macro=true";
import { default as indexoq6nzA98eWMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue?macro=true";
import { default as indexStsBrC3FC0Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue?macro=true";
import { default as indexRaEbNiLYJWMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue?macro=true";
import { default as indexKLY6qEFU45Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue?macro=true";
import { default as indexzLr8KaialWMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue?macro=true";
import { default as _91id_93yZxIsmzmecMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue?macro=true";
import { default as index8vViYIq9kKMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue?macro=true";
import { default as indexYif8uFXzN3Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue?macro=true";
import { default as _91id_93eFAgVqR8FwMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue?macro=true";
import { default as indexTti7YFnKJIMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue?macro=true";
import { default as _91id_939dhgAiF6MiMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue?macro=true";
import { default as indexYMzfqn5EFpMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue?macro=true";
import { default as index8apqxCLZKvMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue?macro=true";
import { default as indexxrRnYFqjftMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue?macro=true";
import { default as indexNA2IPcxv6bMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue?macro=true";
import { default as indexvmGVaQk9HCMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue?macro=true";
import { default as indexTzIxqLin8WMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue?macro=true";
import { default as indexs2x0L4NXz3Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue?macro=true";
import { default as indexOj32V75fEMMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue?macro=true";
import { default as index67aTtmNLFHMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue?macro=true";
import { default as indexL655a4cpsUMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue?macro=true";
import { default as editLrhPM2MrmDMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue?macro=true";
import { default as indexV1DWrjdR9bMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue?macro=true";
import { default as indexWO0jPcEA7QMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue?macro=true";
import { default as indeximOucWmOndMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue?macro=true";
import { default as indexszuTaSPQw3Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue?macro=true";
import { default as indexco0E0PlSg2Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue?macro=true";
import { default as indexxfL8GXQ3V3Meta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue?macro=true";
import { default as indexk2uvwM5yDOMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue?macro=true";
import { default as indexnkY0MRYRQlMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue?macro=true";
import { default as _91id_93HPndY0gHSnMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue?macro=true";
import { default as index3NXTufH7aCMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue?macro=true";
import { default as _91id_936rfUfWgXcHMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue?macro=true";
import { default as indexTzPEqwBmVcMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue?macro=true";
import { default as _91id_93GCsPbpPkSoMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue?macro=true";
import { default as indexpDsuzpMLAuMeta } from "/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue?macro=true";
import { default as indexvRPAQ1K5EIMeta } from "/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue?macro=true";
import { default as indexEMQP7hGoQ2Meta } from "/home/runner/work/ralph-web/ralph-web/pages/index.vue?macro=true";
import { default as index6d6QH3MBlDMeta } from "/home/runner/work/ralph-web/ralph-web/pages/login/index.vue?macro=true";
import { default as indexzJzThXuNuWMeta } from "/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue?macro=true";
import { default as indexqX8dSIkAKAMeta } from "/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue?macro=true";
import { default as index78M2MIBBkxMeta } from "/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue?macro=true";
import { default as indexlRq4UtRkqTMeta } from "/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue?macro=true";
export default [
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___en",
    path: indeximyHB8hHuEMeta?.path ?? "/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___fr",
    path: indeximyHB8hHuEMeta?.path ?? "/fr/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___sv",
    path: indeximyHB8hHuEMeta?.path ?? "/sv/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___ch",
    path: indeximyHB8hHuEMeta?.path ?? "/ch/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___de",
    path: indeximyHB8hHuEMeta?.path ?? "/de/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___da",
    path: indeximyHB8hHuEMeta?.path ?? "/da/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___dk",
    path: indeximyHB8hHuEMeta?.path ?? "/dk/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___nl",
    path: indeximyHB8hHuEMeta?.path ?? "/nl/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indeximyHB8hHuEMeta?.name ?? "dashboard-activity-log___no",
    path: indeximyHB8hHuEMeta?.path ?? "/no/dashboard/activity-log",
    meta: indeximyHB8hHuEMeta || {},
    alias: indeximyHB8hHuEMeta?.alias || [],
    redirect: indeximyHB8hHuEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/activity-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___en",
    path: indexmZ0TLnm6WUMeta?.path ?? "/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___fr",
    path: indexmZ0TLnm6WUMeta?.path ?? "/fr/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___sv",
    path: indexmZ0TLnm6WUMeta?.path ?? "/sv/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___ch",
    path: indexmZ0TLnm6WUMeta?.path ?? "/ch/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___de",
    path: indexmZ0TLnm6WUMeta?.path ?? "/de/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___da",
    path: indexmZ0TLnm6WUMeta?.path ?? "/da/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___dk",
    path: indexmZ0TLnm6WUMeta?.path ?? "/dk/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___nl",
    path: indexmZ0TLnm6WUMeta?.path ?? "/nl/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmZ0TLnm6WUMeta?.name ?? "dashboard-customer-support-id___no",
    path: indexmZ0TLnm6WUMeta?.path ?? "/no/dashboard/customer-support/:id()",
    meta: indexmZ0TLnm6WUMeta || {},
    alias: indexmZ0TLnm6WUMeta?.alias || [],
    redirect: indexmZ0TLnm6WUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___en",
    path: indexmQebns9TshMeta?.path ?? "/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___fr",
    path: indexmQebns9TshMeta?.path ?? "/fr/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___sv",
    path: indexmQebns9TshMeta?.path ?? "/sv/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___ch",
    path: indexmQebns9TshMeta?.path ?? "/ch/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___de",
    path: indexmQebns9TshMeta?.path ?? "/de/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___da",
    path: indexmQebns9TshMeta?.path ?? "/da/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___dk",
    path: indexmQebns9TshMeta?.path ?? "/dk/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___nl",
    path: indexmQebns9TshMeta?.path ?? "/nl/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexmQebns9TshMeta?.name ?? "dashboard-customer-support___no",
    path: indexmQebns9TshMeta?.path ?? "/no/dashboard/customer-support",
    meta: indexmQebns9TshMeta || {},
    alias: indexmQebns9TshMeta?.alias || [],
    redirect: indexmQebns9TshMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customer-support/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___en",
    path: indexoq6nzA98eWMeta?.path ?? "/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___fr",
    path: indexoq6nzA98eWMeta?.path ?? "/fr/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___sv",
    path: indexoq6nzA98eWMeta?.path ?? "/sv/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___ch",
    path: indexoq6nzA98eWMeta?.path ?? "/ch/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___de",
    path: indexoq6nzA98eWMeta?.path ?? "/de/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___da",
    path: indexoq6nzA98eWMeta?.path ?? "/da/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___dk",
    path: indexoq6nzA98eWMeta?.path ?? "/dk/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___nl",
    path: indexoq6nzA98eWMeta?.path ?? "/nl/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexoq6nzA98eWMeta?.name ?? "dashboard-customers-id-fields___no",
    path: indexoq6nzA98eWMeta?.path ?? "/no/dashboard/customers/:id()/fields",
    meta: indexoq6nzA98eWMeta || {},
    alias: indexoq6nzA98eWMeta?.alias || [],
    redirect: indexoq6nzA98eWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___en",
    path: indexStsBrC3FC0Meta?.path ?? "/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___fr",
    path: indexStsBrC3FC0Meta?.path ?? "/fr/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___sv",
    path: indexStsBrC3FC0Meta?.path ?? "/sv/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___ch",
    path: indexStsBrC3FC0Meta?.path ?? "/ch/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___de",
    path: indexStsBrC3FC0Meta?.path ?? "/de/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___da",
    path: indexStsBrC3FC0Meta?.path ?? "/da/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___dk",
    path: indexStsBrC3FC0Meta?.path ?? "/dk/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___nl",
    path: indexStsBrC3FC0Meta?.path ?? "/nl/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexStsBrC3FC0Meta?.name ?? "dashboard-customers-id___no",
    path: indexStsBrC3FC0Meta?.path ?? "/no/dashboard/customers/:id()",
    meta: indexStsBrC3FC0Meta || {},
    alias: indexStsBrC3FC0Meta?.alias || [],
    redirect: indexStsBrC3FC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___en",
    path: indexRaEbNiLYJWMeta?.path ?? "/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___fr",
    path: indexRaEbNiLYJWMeta?.path ?? "/fr/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___sv",
    path: indexRaEbNiLYJWMeta?.path ?? "/sv/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___ch",
    path: indexRaEbNiLYJWMeta?.path ?? "/ch/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___de",
    path: indexRaEbNiLYJWMeta?.path ?? "/de/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___da",
    path: indexRaEbNiLYJWMeta?.path ?? "/da/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___dk",
    path: indexRaEbNiLYJWMeta?.path ?? "/dk/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___nl",
    path: indexRaEbNiLYJWMeta?.path ?? "/nl/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexRaEbNiLYJWMeta?.name ?? "dashboard-customers-id-sport-parks___no",
    path: indexRaEbNiLYJWMeta?.path ?? "/no/dashboard/customers/:id()/sport-parks",
    meta: indexRaEbNiLYJWMeta || {},
    alias: indexRaEbNiLYJWMeta?.alias || [],
    redirect: indexRaEbNiLYJWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___en",
    path: indexKLY6qEFU45Meta?.path ?? "/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___fr",
    path: indexKLY6qEFU45Meta?.path ?? "/fr/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___sv",
    path: indexKLY6qEFU45Meta?.path ?? "/sv/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___ch",
    path: indexKLY6qEFU45Meta?.path ?? "/ch/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___de",
    path: indexKLY6qEFU45Meta?.path ?? "/de/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___da",
    path: indexKLY6qEFU45Meta?.path ?? "/da/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___dk",
    path: indexKLY6qEFU45Meta?.path ?? "/dk/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___nl",
    path: indexKLY6qEFU45Meta?.path ?? "/nl/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLY6qEFU45Meta?.name ?? "dashboard-customers-id-users___no",
    path: indexKLY6qEFU45Meta?.path ?? "/no/dashboard/customers/:id()/users",
    meta: indexKLY6qEFU45Meta || {},
    alias: indexKLY6qEFU45Meta?.alias || [],
    redirect: indexKLY6qEFU45Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/[id]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___en",
    path: indexzLr8KaialWMeta?.path ?? "/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___fr",
    path: indexzLr8KaialWMeta?.path ?? "/fr/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___sv",
    path: indexzLr8KaialWMeta?.path ?? "/sv/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___ch",
    path: indexzLr8KaialWMeta?.path ?? "/ch/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___de",
    path: indexzLr8KaialWMeta?.path ?? "/de/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___da",
    path: indexzLr8KaialWMeta?.path ?? "/da/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___dk",
    path: indexzLr8KaialWMeta?.path ?? "/dk/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___nl",
    path: indexzLr8KaialWMeta?.path ?? "/nl/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzLr8KaialWMeta?.name ?? "dashboard-customers-add___no",
    path: indexzLr8KaialWMeta?.path ?? "/no/dashboard/customers/add",
    meta: indexzLr8KaialWMeta || {},
    alias: indexzLr8KaialWMeta?.alias || [],
    redirect: indexzLr8KaialWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/add/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___en",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___fr",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/fr/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___sv",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/sv/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___ch",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/ch/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___de",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/de/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___da",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/da/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___dk",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/dk/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___nl",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/nl/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yZxIsmzmecMeta?.name ?? "dashboard-customers-edit-id___no",
    path: _91id_93yZxIsmzmecMeta?.path ?? "/no/dashboard/customers/edit/:id()",
    meta: _91id_93yZxIsmzmecMeta || {},
    alias: _91id_93yZxIsmzmecMeta?.alias || [],
    redirect: _91id_93yZxIsmzmecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___en",
    path: index8vViYIq9kKMeta?.path ?? "/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___fr",
    path: index8vViYIq9kKMeta?.path ?? "/fr/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___sv",
    path: index8vViYIq9kKMeta?.path ?? "/sv/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___ch",
    path: index8vViYIq9kKMeta?.path ?? "/ch/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___de",
    path: index8vViYIq9kKMeta?.path ?? "/de/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___da",
    path: index8vViYIq9kKMeta?.path ?? "/da/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___dk",
    path: index8vViYIq9kKMeta?.path ?? "/dk/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___nl",
    path: index8vViYIq9kKMeta?.path ?? "/nl/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index8vViYIq9kKMeta?.name ?? "dashboard-customers___no",
    path: index8vViYIq9kKMeta?.path ?? "/no/dashboard/customers",
    meta: index8vViYIq9kKMeta || {},
    alias: index8vViYIq9kKMeta?.alias || [],
    redirect: index8vViYIq9kKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___en",
    path: indexYif8uFXzN3Meta?.path ?? "/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___fr",
    path: indexYif8uFXzN3Meta?.path ?? "/fr/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___sv",
    path: indexYif8uFXzN3Meta?.path ?? "/sv/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___ch",
    path: indexYif8uFXzN3Meta?.path ?? "/ch/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___de",
    path: indexYif8uFXzN3Meta?.path ?? "/de/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___da",
    path: indexYif8uFXzN3Meta?.path ?? "/da/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___dk",
    path: indexYif8uFXzN3Meta?.path ?? "/dk/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___nl",
    path: indexYif8uFXzN3Meta?.path ?? "/nl/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexYif8uFXzN3Meta?.name ?? "dashboard___no",
    path: indexYif8uFXzN3Meta?.path ?? "/no/dashboard",
    meta: indexYif8uFXzN3Meta || {},
    alias: indexYif8uFXzN3Meta?.alias || [],
    redirect: indexYif8uFXzN3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___en",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___fr",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/fr/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___sv",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/sv/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___ch",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/ch/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___de",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/de/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___da",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/da/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___dk",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/dk/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___nl",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/nl/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93eFAgVqR8FwMeta?.name ?? "dashboard-notifications-id___no",
    path: _91id_93eFAgVqR8FwMeta?.path ?? "/no/dashboard/notifications/:id()",
    meta: _91id_93eFAgVqR8FwMeta || {},
    alias: _91id_93eFAgVqR8FwMeta?.alias || [],
    redirect: _91id_93eFAgVqR8FwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___en",
    path: indexTti7YFnKJIMeta?.path ?? "/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___fr",
    path: indexTti7YFnKJIMeta?.path ?? "/fr/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___sv",
    path: indexTti7YFnKJIMeta?.path ?? "/sv/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___ch",
    path: indexTti7YFnKJIMeta?.path ?? "/ch/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___de",
    path: indexTti7YFnKJIMeta?.path ?? "/de/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___da",
    path: indexTti7YFnKJIMeta?.path ?? "/da/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___dk",
    path: indexTti7YFnKJIMeta?.path ?? "/dk/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___nl",
    path: indexTti7YFnKJIMeta?.path ?? "/nl/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTti7YFnKJIMeta?.name ?? "dashboard-notifications-add___no",
    path: indexTti7YFnKJIMeta?.path ?? "/no/dashboard/notifications/add",
    meta: indexTti7YFnKJIMeta || {},
    alias: indexTti7YFnKJIMeta?.alias || [],
    redirect: indexTti7YFnKJIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/add/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___en",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___fr",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/fr/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___sv",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/sv/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___ch",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/ch/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___de",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/de/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___da",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/da/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___dk",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/dk/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___nl",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/nl/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939dhgAiF6MiMeta?.name ?? "dashboard-notifications-edit-id___no",
    path: _91id_939dhgAiF6MiMeta?.path ?? "/no/dashboard/notifications/edit/:id()",
    meta: _91id_939dhgAiF6MiMeta || {},
    alias: _91id_939dhgAiF6MiMeta?.alias || [],
    redirect: _91id_939dhgAiF6MiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___en",
    path: indexYMzfqn5EFpMeta?.path ?? "/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___fr",
    path: indexYMzfqn5EFpMeta?.path ?? "/fr/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___sv",
    path: indexYMzfqn5EFpMeta?.path ?? "/sv/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___ch",
    path: indexYMzfqn5EFpMeta?.path ?? "/ch/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___de",
    path: indexYMzfqn5EFpMeta?.path ?? "/de/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___da",
    path: indexYMzfqn5EFpMeta?.path ?? "/da/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___dk",
    path: indexYMzfqn5EFpMeta?.path ?? "/dk/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___nl",
    path: indexYMzfqn5EFpMeta?.path ?? "/nl/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMzfqn5EFpMeta?.name ?? "dashboard-notifications___no",
    path: indexYMzfqn5EFpMeta?.path ?? "/no/dashboard/notifications",
    meta: indexYMzfqn5EFpMeta || {},
    alias: indexYMzfqn5EFpMeta?.alias || [],
    redirect: indexYMzfqn5EFpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___en",
    path: index8apqxCLZKvMeta?.path ?? "/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___fr",
    path: index8apqxCLZKvMeta?.path ?? "/fr/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___sv",
    path: index8apqxCLZKvMeta?.path ?? "/sv/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___ch",
    path: index8apqxCLZKvMeta?.path ?? "/ch/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___de",
    path: index8apqxCLZKvMeta?.path ?? "/de/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___da",
    path: index8apqxCLZKvMeta?.path ?? "/da/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___dk",
    path: index8apqxCLZKvMeta?.path ?? "/dk/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___nl",
    path: index8apqxCLZKvMeta?.path ?? "/nl/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index8apqxCLZKvMeta?.name ?? "dashboard-profile___no",
    path: index8apqxCLZKvMeta?.path ?? "/no/dashboard/profile",
    meta: index8apqxCLZKvMeta || {},
    alias: index8apqxCLZKvMeta?.alias || [],
    redirect: index8apqxCLZKvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___en",
    path: indexxrRnYFqjftMeta?.path ?? "/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___fr",
    path: indexxrRnYFqjftMeta?.path ?? "/fr/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___sv",
    path: indexxrRnYFqjftMeta?.path ?? "/sv/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___ch",
    path: indexxrRnYFqjftMeta?.path ?? "/ch/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___de",
    path: indexxrRnYFqjftMeta?.path ?? "/de/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___da",
    path: indexxrRnYFqjftMeta?.path ?? "/da/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___dk",
    path: indexxrRnYFqjftMeta?.path ?? "/dk/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___nl",
    path: indexxrRnYFqjftMeta?.path ?? "/nl/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrRnYFqjftMeta?.name ?? "dashboard-reports-id___no",
    path: indexxrRnYFqjftMeta?.path ?? "/no/dashboard/reports/:id()",
    meta: indexxrRnYFqjftMeta || {},
    alias: indexxrRnYFqjftMeta?.alias || [],
    redirect: indexxrRnYFqjftMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___en",
    path: indexNA2IPcxv6bMeta?.path ?? "/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___fr",
    path: indexNA2IPcxv6bMeta?.path ?? "/fr/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___sv",
    path: indexNA2IPcxv6bMeta?.path ?? "/sv/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___ch",
    path: indexNA2IPcxv6bMeta?.path ?? "/ch/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___de",
    path: indexNA2IPcxv6bMeta?.path ?? "/de/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___da",
    path: indexNA2IPcxv6bMeta?.path ?? "/da/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___dk",
    path: indexNA2IPcxv6bMeta?.path ?? "/dk/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___nl",
    path: indexNA2IPcxv6bMeta?.path ?? "/nl/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA2IPcxv6bMeta?.name ?? "dashboard-reports-add___no",
    path: indexNA2IPcxv6bMeta?.path ?? "/no/dashboard/reports/add",
    meta: indexNA2IPcxv6bMeta || {},
    alias: indexNA2IPcxv6bMeta?.alias || [],
    redirect: indexNA2IPcxv6bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___en",
    path: indexvmGVaQk9HCMeta?.path ?? "/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___fr",
    path: indexvmGVaQk9HCMeta?.path ?? "/fr/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___sv",
    path: indexvmGVaQk9HCMeta?.path ?? "/sv/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___ch",
    path: indexvmGVaQk9HCMeta?.path ?? "/ch/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___de",
    path: indexvmGVaQk9HCMeta?.path ?? "/de/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___da",
    path: indexvmGVaQk9HCMeta?.path ?? "/da/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___dk",
    path: indexvmGVaQk9HCMeta?.path ?? "/dk/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___nl",
    path: indexvmGVaQk9HCMeta?.path ?? "/nl/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexvmGVaQk9HCMeta?.name ?? "dashboard-reports___no",
    path: indexvmGVaQk9HCMeta?.path ?? "/no/dashboard/reports",
    meta: indexvmGVaQk9HCMeta || {},
    alias: indexvmGVaQk9HCMeta?.alias || [],
    redirect: indexvmGVaQk9HCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___en",
    path: indexTzIxqLin8WMeta?.path ?? "/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___fr",
    path: indexTzIxqLin8WMeta?.path ?? "/fr/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___sv",
    path: indexTzIxqLin8WMeta?.path ?? "/sv/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___ch",
    path: indexTzIxqLin8WMeta?.path ?? "/ch/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___de",
    path: indexTzIxqLin8WMeta?.path ?? "/de/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___da",
    path: indexTzIxqLin8WMeta?.path ?? "/da/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___dk",
    path: indexTzIxqLin8WMeta?.path ?? "/dk/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___nl",
    path: indexTzIxqLin8WMeta?.path ?? "/nl/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzIxqLin8WMeta?.name ?? "dashboard-server-log___no",
    path: indexTzIxqLin8WMeta?.path ?? "/no/dashboard/server-log",
    meta: indexTzIxqLin8WMeta || {},
    alias: indexTzIxqLin8WMeta?.alias || [],
    redirect: indexTzIxqLin8WMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/server-log/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___en",
    path: indexs2x0L4NXz3Meta?.path ?? "/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___fr",
    path: indexs2x0L4NXz3Meta?.path ?? "/fr/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___sv",
    path: indexs2x0L4NXz3Meta?.path ?? "/sv/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___ch",
    path: indexs2x0L4NXz3Meta?.path ?? "/ch/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___de",
    path: indexs2x0L4NXz3Meta?.path ?? "/de/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___da",
    path: indexs2x0L4NXz3Meta?.path ?? "/da/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___dk",
    path: indexs2x0L4NXz3Meta?.path ?? "/dk/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___nl",
    path: indexs2x0L4NXz3Meta?.path ?? "/nl/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexs2x0L4NXz3Meta?.name ?? "dashboard-sport-parks-id-boxes___no",
    path: indexs2x0L4NXz3Meta?.path ?? "/no/dashboard/sport-parks/:id()/boxes",
    meta: indexs2x0L4NXz3Meta || {},
    alias: indexs2x0L4NXz3Meta?.alias || [],
    redirect: indexs2x0L4NXz3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___en",
    path: indexOj32V75fEMMeta?.path ?? "/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___fr",
    path: indexOj32V75fEMMeta?.path ?? "/fr/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___sv",
    path: indexOj32V75fEMMeta?.path ?? "/sv/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___ch",
    path: indexOj32V75fEMMeta?.path ?? "/ch/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___de",
    path: indexOj32V75fEMMeta?.path ?? "/de/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___da",
    path: indexOj32V75fEMMeta?.path ?? "/da/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___dk",
    path: indexOj32V75fEMMeta?.path ?? "/dk/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___nl",
    path: indexOj32V75fEMMeta?.path ?? "/nl/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: indexOj32V75fEMMeta?.name ?? "dashboard-sport-parks-id-consumption___no",
    path: indexOj32V75fEMMeta?.path ?? "/no/dashboard/sport-parks/:id()/consumption",
    meta: indexOj32V75fEMMeta || {},
    alias: indexOj32V75fEMMeta?.alias || [],
    redirect: indexOj32V75fEMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/consumption/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___en",
    path: index67aTtmNLFHMeta?.path ?? "/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___fr",
    path: index67aTtmNLFHMeta?.path ?? "/fr/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___sv",
    path: index67aTtmNLFHMeta?.path ?? "/sv/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___ch",
    path: index67aTtmNLFHMeta?.path ?? "/ch/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___de",
    path: index67aTtmNLFHMeta?.path ?? "/de/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___da",
    path: index67aTtmNLFHMeta?.path ?? "/da/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___dk",
    path: index67aTtmNLFHMeta?.path ?? "/dk/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___nl",
    path: index67aTtmNLFHMeta?.path ?? "/nl/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: index67aTtmNLFHMeta?.name ?? "dashboard-sport-parks-id-event-schedule___no",
    path: index67aTtmNLFHMeta?.path ?? "/no/dashboard/sport-parks/:id()/event-schedule",
    meta: index67aTtmNLFHMeta || {},
    alias: index67aTtmNLFHMeta?.alias || [],
    redirect: index67aTtmNLFHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/event-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___en",
    path: indexL655a4cpsUMeta?.path ?? "/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___fr",
    path: indexL655a4cpsUMeta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___sv",
    path: indexL655a4cpsUMeta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___ch",
    path: indexL655a4cpsUMeta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___de",
    path: indexL655a4cpsUMeta?.path ?? "/de/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___da",
    path: indexL655a4cpsUMeta?.path ?? "/da/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___dk",
    path: indexL655a4cpsUMeta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___nl",
    path: indexL655a4cpsUMeta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: indexL655a4cpsUMeta?.name ?? "dashboard-sport-parks-id-fields-id-box-configuration___no",
    path: indexL655a4cpsUMeta?.path ?? "/no/dashboard/sport-parks/:id()/fields/:id()/box-configuration",
    meta: indexL655a4cpsUMeta || {},
    alias: indexL655a4cpsUMeta?.alias || [],
    redirect: indexL655a4cpsUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/box-configuration/index.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___en",
    path: editLrhPM2MrmDMeta?.path ?? "/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___fr",
    path: editLrhPM2MrmDMeta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___sv",
    path: editLrhPM2MrmDMeta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___ch",
    path: editLrhPM2MrmDMeta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___de",
    path: editLrhPM2MrmDMeta?.path ?? "/de/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___da",
    path: editLrhPM2MrmDMeta?.path ?? "/da/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___dk",
    path: editLrhPM2MrmDMeta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___nl",
    path: editLrhPM2MrmDMeta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: editLrhPM2MrmDMeta?.name ?? "dashboard-sport-parks-id-fields-id-edit___no",
    path: editLrhPM2MrmDMeta?.path ?? "/no/dashboard/sport-parks/:id()/fields/:id()/edit",
    meta: editLrhPM2MrmDMeta || {},
    alias: editLrhPM2MrmDMeta?.alias || [],
    redirect: editLrhPM2MrmDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___en",
    path: indexV1DWrjdR9bMeta?.path ?? "/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___fr",
    path: indexV1DWrjdR9bMeta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___sv",
    path: indexV1DWrjdR9bMeta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___ch",
    path: indexV1DWrjdR9bMeta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___de",
    path: indexV1DWrjdR9bMeta?.path ?? "/de/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___da",
    path: indexV1DWrjdR9bMeta?.path ?? "/da/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___dk",
    path: indexV1DWrjdR9bMeta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___nl",
    path: indexV1DWrjdR9bMeta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexV1DWrjdR9bMeta?.name ?? "dashboard-sport-parks-id-fields-id-hardware___no",
    path: indexV1DWrjdR9bMeta?.path ?? "/no/dashboard/sport-parks/:id()/fields/:id()/hardware",
    meta: indexV1DWrjdR9bMeta || {},
    alias: indexV1DWrjdR9bMeta?.alias || [],
    redirect: indexV1DWrjdR9bMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___en",
    path: indexWO0jPcEA7QMeta?.path ?? "/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___fr",
    path: indexWO0jPcEA7QMeta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___sv",
    path: indexWO0jPcEA7QMeta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___ch",
    path: indexWO0jPcEA7QMeta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___de",
    path: indexWO0jPcEA7QMeta?.path ?? "/de/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___da",
    path: indexWO0jPcEA7QMeta?.path ?? "/da/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___dk",
    path: indexWO0jPcEA7QMeta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___nl",
    path: indexWO0jPcEA7QMeta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWO0jPcEA7QMeta?.name ?? "dashboard-sport-parks-id-fields-id___no",
    path: indexWO0jPcEA7QMeta?.path ?? "/no/dashboard/sport-parks/:id()/fields/:id()",
    meta: indexWO0jPcEA7QMeta || {},
    alias: indexWO0jPcEA7QMeta?.alias || [],
    redirect: indexWO0jPcEA7QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___en",
    path: indeximOucWmOndMeta?.path ?? "/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___fr",
    path: indeximOucWmOndMeta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___sv",
    path: indeximOucWmOndMeta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___ch",
    path: indeximOucWmOndMeta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___de",
    path: indeximOucWmOndMeta?.path ?? "/de/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___da",
    path: indeximOucWmOndMeta?.path ?? "/da/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___dk",
    path: indeximOucWmOndMeta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___nl",
    path: indeximOucWmOndMeta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indeximOucWmOndMeta?.name ?? "dashboard-sport-parks-id-fields-id-light___no",
    path: indeximOucWmOndMeta?.path ?? "/no/dashboard/sport-parks/:id()/fields/:id()/light",
    meta: indeximOucWmOndMeta || {},
    alias: indeximOucWmOndMeta?.alias || [],
    redirect: indeximOucWmOndMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/light/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___en",
    path: indexszuTaSPQw3Meta?.path ?? "/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___fr",
    path: indexszuTaSPQw3Meta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___sv",
    path: indexszuTaSPQw3Meta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___ch",
    path: indexszuTaSPQw3Meta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___de",
    path: indexszuTaSPQw3Meta?.path ?? "/de/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___da",
    path: indexszuTaSPQw3Meta?.path ?? "/da/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___dk",
    path: indexszuTaSPQw3Meta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___nl",
    path: indexszuTaSPQw3Meta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexszuTaSPQw3Meta?.name ?? "dashboard-sport-parks-id-fields-id-time-schedule___no",
    path: indexszuTaSPQw3Meta?.path ?? "/no/dashboard/sport-parks/:id()/fields/:id()/time-schedule",
    meta: indexszuTaSPQw3Meta || {},
    alias: indexszuTaSPQw3Meta?.alias || [],
    redirect: indexszuTaSPQw3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/[id]/time-schedule/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___en",
    path: indexco0E0PlSg2Meta?.path ?? "/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___fr",
    path: indexco0E0PlSg2Meta?.path ?? "/fr/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___sv",
    path: indexco0E0PlSg2Meta?.path ?? "/sv/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___ch",
    path: indexco0E0PlSg2Meta?.path ?? "/ch/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___de",
    path: indexco0E0PlSg2Meta?.path ?? "/de/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___da",
    path: indexco0E0PlSg2Meta?.path ?? "/da/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___dk",
    path: indexco0E0PlSg2Meta?.path ?? "/dk/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___nl",
    path: indexco0E0PlSg2Meta?.path ?? "/nl/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexco0E0PlSg2Meta?.name ?? "dashboard-sport-parks-id-fields-add___no",
    path: indexco0E0PlSg2Meta?.path ?? "/no/dashboard/sport-parks/:id()/fields/add",
    meta: indexco0E0PlSg2Meta || {},
    alias: indexco0E0PlSg2Meta?.alias || [],
    redirect: indexco0E0PlSg2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___en",
    path: indexxfL8GXQ3V3Meta?.path ?? "/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___fr",
    path: indexxfL8GXQ3V3Meta?.path ?? "/fr/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___sv",
    path: indexxfL8GXQ3V3Meta?.path ?? "/sv/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___ch",
    path: indexxfL8GXQ3V3Meta?.path ?? "/ch/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___de",
    path: indexxfL8GXQ3V3Meta?.path ?? "/de/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___da",
    path: indexxfL8GXQ3V3Meta?.path ?? "/da/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___dk",
    path: indexxfL8GXQ3V3Meta?.path ?? "/dk/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___nl",
    path: indexxfL8GXQ3V3Meta?.path ?? "/nl/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexxfL8GXQ3V3Meta?.name ?? "dashboard-sport-parks-id-fields___no",
    path: indexxfL8GXQ3V3Meta?.path ?? "/no/dashboard/sport-parks/:id()/fields",
    meta: indexxfL8GXQ3V3Meta || {},
    alias: indexxfL8GXQ3V3Meta?.alias || [],
    redirect: indexxfL8GXQ3V3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/fields/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___en",
    path: indexk2uvwM5yDOMeta?.path ?? "/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___fr",
    path: indexk2uvwM5yDOMeta?.path ?? "/fr/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___sv",
    path: indexk2uvwM5yDOMeta?.path ?? "/sv/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___ch",
    path: indexk2uvwM5yDOMeta?.path ?? "/ch/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___de",
    path: indexk2uvwM5yDOMeta?.path ?? "/de/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___da",
    path: indexk2uvwM5yDOMeta?.path ?? "/da/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___dk",
    path: indexk2uvwM5yDOMeta?.path ?? "/dk/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___nl",
    path: indexk2uvwM5yDOMeta?.path ?? "/nl/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexk2uvwM5yDOMeta?.name ?? "dashboard-sport-parks-id___no",
    path: indexk2uvwM5yDOMeta?.path ?? "/no/dashboard/sport-parks/:id()",
    meta: indexk2uvwM5yDOMeta || {},
    alias: indexk2uvwM5yDOMeta?.alias || [],
    redirect: indexk2uvwM5yDOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___en",
    path: indexnkY0MRYRQlMeta?.path ?? "/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___fr",
    path: indexnkY0MRYRQlMeta?.path ?? "/fr/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___sv",
    path: indexnkY0MRYRQlMeta?.path ?? "/sv/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___ch",
    path: indexnkY0MRYRQlMeta?.path ?? "/ch/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___de",
    path: indexnkY0MRYRQlMeta?.path ?? "/de/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___da",
    path: indexnkY0MRYRQlMeta?.path ?? "/da/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___dk",
    path: indexnkY0MRYRQlMeta?.path ?? "/dk/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___nl",
    path: indexnkY0MRYRQlMeta?.path ?? "/nl/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkY0MRYRQlMeta?.name ?? "dashboard-sport-parks-add___no",
    path: indexnkY0MRYRQlMeta?.path ?? "/no/dashboard/sport-parks/add",
    meta: indexnkY0MRYRQlMeta || {},
    alias: indexnkY0MRYRQlMeta?.alias || [],
    redirect: indexnkY0MRYRQlMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/add/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___en",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___fr",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/fr/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___sv",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/sv/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___ch",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/ch/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___de",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/de/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___da",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/da/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___dk",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/dk/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___nl",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/nl/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HPndY0gHSnMeta?.name ?? "dashboard-sport-parks-edit-id___no",
    path: _91id_93HPndY0gHSnMeta?.path ?? "/no/dashboard/sport-parks/edit/:id()",
    meta: _91id_93HPndY0gHSnMeta || {},
    alias: _91id_93HPndY0gHSnMeta?.alias || [],
    redirect: _91id_93HPndY0gHSnMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___en",
    path: index3NXTufH7aCMeta?.path ?? "/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___fr",
    path: index3NXTufH7aCMeta?.path ?? "/fr/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___sv",
    path: index3NXTufH7aCMeta?.path ?? "/sv/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___ch",
    path: index3NXTufH7aCMeta?.path ?? "/ch/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___de",
    path: index3NXTufH7aCMeta?.path ?? "/de/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___da",
    path: index3NXTufH7aCMeta?.path ?? "/da/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___dk",
    path: index3NXTufH7aCMeta?.path ?? "/dk/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___nl",
    path: index3NXTufH7aCMeta?.path ?? "/nl/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: index3NXTufH7aCMeta?.name ?? "dashboard-sport-parks___no",
    path: index3NXTufH7aCMeta?.path ?? "/no/dashboard/sport-parks",
    meta: index3NXTufH7aCMeta || {},
    alias: index3NXTufH7aCMeta?.alias || [],
    redirect: index3NXTufH7aCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/sport-parks/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___en",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___fr",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/fr/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___sv",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/sv/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___ch",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/ch/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___de",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/de/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___da",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/da/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___dk",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/dk/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___nl",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/nl/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936rfUfWgXcHMeta?.name ?? "dashboard-users-id___no",
    path: _91id_936rfUfWgXcHMeta?.path ?? "/no/dashboard/users/:id()",
    meta: _91id_936rfUfWgXcHMeta || {},
    alias: _91id_936rfUfWgXcHMeta?.alias || [],
    redirect: _91id_936rfUfWgXcHMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___en",
    path: indexTzPEqwBmVcMeta?.path ?? "/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___fr",
    path: indexTzPEqwBmVcMeta?.path ?? "/fr/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___sv",
    path: indexTzPEqwBmVcMeta?.path ?? "/sv/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___ch",
    path: indexTzPEqwBmVcMeta?.path ?? "/ch/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___de",
    path: indexTzPEqwBmVcMeta?.path ?? "/de/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___da",
    path: indexTzPEqwBmVcMeta?.path ?? "/da/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___dk",
    path: indexTzPEqwBmVcMeta?.path ?? "/dk/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___nl",
    path: indexTzPEqwBmVcMeta?.path ?? "/nl/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexTzPEqwBmVcMeta?.name ?? "dashboard-users-add___no",
    path: indexTzPEqwBmVcMeta?.path ?? "/no/dashboard/users/add",
    meta: indexTzPEqwBmVcMeta || {},
    alias: indexTzPEqwBmVcMeta?.alias || [],
    redirect: indexTzPEqwBmVcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___en",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___fr",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/fr/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___sv",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/sv/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___ch",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/ch/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___de",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/de/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___da",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/da/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___dk",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/dk/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___nl",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/nl/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93GCsPbpPkSoMeta?.name ?? "dashboard-users-edit-id___no",
    path: _91id_93GCsPbpPkSoMeta?.path ?? "/no/dashboard/users/edit/:id()",
    meta: _91id_93GCsPbpPkSoMeta || {},
    alias: _91id_93GCsPbpPkSoMeta?.alias || [],
    redirect: _91id_93GCsPbpPkSoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___en",
    path: indexpDsuzpMLAuMeta?.path ?? "/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___fr",
    path: indexpDsuzpMLAuMeta?.path ?? "/fr/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___sv",
    path: indexpDsuzpMLAuMeta?.path ?? "/sv/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___ch",
    path: indexpDsuzpMLAuMeta?.path ?? "/ch/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___de",
    path: indexpDsuzpMLAuMeta?.path ?? "/de/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___da",
    path: indexpDsuzpMLAuMeta?.path ?? "/da/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___dk",
    path: indexpDsuzpMLAuMeta?.path ?? "/dk/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___nl",
    path: indexpDsuzpMLAuMeta?.path ?? "/nl/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexpDsuzpMLAuMeta?.name ?? "dashboard-users___no",
    path: indexpDsuzpMLAuMeta?.path ?? "/no/dashboard/users",
    meta: indexpDsuzpMLAuMeta || {},
    alias: indexpDsuzpMLAuMeta?.alias || [],
    redirect: indexpDsuzpMLAuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___en",
    path: indexvRPAQ1K5EIMeta?.path ?? "/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___fr",
    path: indexvRPAQ1K5EIMeta?.path ?? "/fr/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___sv",
    path: indexvRPAQ1K5EIMeta?.path ?? "/sv/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___ch",
    path: indexvRPAQ1K5EIMeta?.path ?? "/ch/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___de",
    path: indexvRPAQ1K5EIMeta?.path ?? "/de/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___da",
    path: indexvRPAQ1K5EIMeta?.path ?? "/da/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___dk",
    path: indexvRPAQ1K5EIMeta?.path ?? "/dk/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___nl",
    path: indexvRPAQ1K5EIMeta?.path ?? "/nl/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexvRPAQ1K5EIMeta?.name ?? "forgot-password___no",
    path: indexvRPAQ1K5EIMeta?.path ?? "/no/forgot-password",
    meta: indexvRPAQ1K5EIMeta || {},
    alias: indexvRPAQ1K5EIMeta?.alias || [],
    redirect: indexvRPAQ1K5EIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___en",
    path: indexEMQP7hGoQ2Meta?.path ?? "/",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___fr",
    path: indexEMQP7hGoQ2Meta?.path ?? "/fr",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___sv",
    path: indexEMQP7hGoQ2Meta?.path ?? "/sv",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___ch",
    path: indexEMQP7hGoQ2Meta?.path ?? "/ch",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___de",
    path: indexEMQP7hGoQ2Meta?.path ?? "/de",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___da",
    path: indexEMQP7hGoQ2Meta?.path ?? "/da",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___dk",
    path: indexEMQP7hGoQ2Meta?.path ?? "/dk",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___nl",
    path: indexEMQP7hGoQ2Meta?.path ?? "/nl",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEMQP7hGoQ2Meta?.name ?? "index___no",
    path: indexEMQP7hGoQ2Meta?.path ?? "/no",
    meta: indexEMQP7hGoQ2Meta || {},
    alias: indexEMQP7hGoQ2Meta?.alias || [],
    redirect: indexEMQP7hGoQ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___en",
    path: index6d6QH3MBlDMeta?.path ?? "/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___fr",
    path: index6d6QH3MBlDMeta?.path ?? "/fr/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___sv",
    path: index6d6QH3MBlDMeta?.path ?? "/sv/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___ch",
    path: index6d6QH3MBlDMeta?.path ?? "/ch/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___de",
    path: index6d6QH3MBlDMeta?.path ?? "/de/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___da",
    path: index6d6QH3MBlDMeta?.path ?? "/da/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___dk",
    path: index6d6QH3MBlDMeta?.path ?? "/dk/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___nl",
    path: index6d6QH3MBlDMeta?.path ?? "/nl/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index6d6QH3MBlDMeta?.name ?? "login___no",
    path: index6d6QH3MBlDMeta?.path ?? "/no/login",
    meta: index6d6QH3MBlDMeta || {},
    alias: index6d6QH3MBlDMeta?.alias || [],
    redirect: index6d6QH3MBlDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___en",
    path: indexzJzThXuNuWMeta?.path ?? "/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___fr",
    path: indexzJzThXuNuWMeta?.path ?? "/fr/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___sv",
    path: indexzJzThXuNuWMeta?.path ?? "/sv/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___ch",
    path: indexzJzThXuNuWMeta?.path ?? "/ch/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___de",
    path: indexzJzThXuNuWMeta?.path ?? "/de/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___da",
    path: indexzJzThXuNuWMeta?.path ?? "/da/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___dk",
    path: indexzJzThXuNuWMeta?.path ?? "/dk/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___nl",
    path: indexzJzThXuNuWMeta?.path ?? "/nl/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexzJzThXuNuWMeta?.name ?? "new-password___no",
    path: indexzJzThXuNuWMeta?.path ?? "/no/new-password",
    meta: indexzJzThXuNuWMeta || {},
    alias: indexzJzThXuNuWMeta?.alias || [],
    redirect: indexzJzThXuNuWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___en",
    path: indexqX8dSIkAKAMeta?.path ?? "/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___fr",
    path: indexqX8dSIkAKAMeta?.path ?? "/fr/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___sv",
    path: indexqX8dSIkAKAMeta?.path ?? "/sv/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___ch",
    path: indexqX8dSIkAKAMeta?.path ?? "/ch/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___de",
    path: indexqX8dSIkAKAMeta?.path ?? "/de/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___da",
    path: indexqX8dSIkAKAMeta?.path ?? "/da/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___dk",
    path: indexqX8dSIkAKAMeta?.path ?? "/dk/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___nl",
    path: indexqX8dSIkAKAMeta?.path ?? "/nl/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqX8dSIkAKAMeta?.name ?? "privacy-policy___no",
    path: indexqX8dSIkAKAMeta?.path ?? "/no/privacy-policy",
    meta: indexqX8dSIkAKAMeta || {},
    alias: indexqX8dSIkAKAMeta?.alias || [],
    redirect: indexqX8dSIkAKAMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___en",
    path: index78M2MIBBkxMeta?.path ?? "/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___fr",
    path: index78M2MIBBkxMeta?.path ?? "/fr/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___sv",
    path: index78M2MIBBkxMeta?.path ?? "/sv/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___ch",
    path: index78M2MIBBkxMeta?.path ?? "/ch/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___de",
    path: index78M2MIBBkxMeta?.path ?? "/de/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___da",
    path: index78M2MIBBkxMeta?.path ?? "/da/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___dk",
    path: index78M2MIBBkxMeta?.path ?? "/dk/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___nl",
    path: index78M2MIBBkxMeta?.path ?? "/nl/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: index78M2MIBBkxMeta?.name ?? "reset-password___no",
    path: index78M2MIBBkxMeta?.path ?? "/no/reset-password",
    meta: index78M2MIBBkxMeta || {},
    alias: index78M2MIBBkxMeta?.alias || [],
    redirect: index78M2MIBBkxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___en",
    path: indexlRq4UtRkqTMeta?.path ?? "/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___fr",
    path: indexlRq4UtRkqTMeta?.path ?? "/fr/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___sv",
    path: indexlRq4UtRkqTMeta?.path ?? "/sv/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___ch",
    path: indexlRq4UtRkqTMeta?.path ?? "/ch/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___de",
    path: indexlRq4UtRkqTMeta?.path ?? "/de/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___da",
    path: indexlRq4UtRkqTMeta?.path ?? "/da/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___dk",
    path: indexlRq4UtRkqTMeta?.path ?? "/dk/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___nl",
    path: indexlRq4UtRkqTMeta?.path ?? "/nl/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRq4UtRkqTMeta?.name ?? "terms-and-conditions___no",
    path: indexlRq4UtRkqTMeta?.path ?? "/no/terms-and-conditions",
    meta: indexlRq4UtRkqTMeta || {},
    alias: indexlRq4UtRkqTMeta?.alias || [],
    redirect: indexlRq4UtRkqTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ralph-web/ralph-web/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  }
]