import revive_payload_client_4sVQNw7RlN from "/home/runner/work/ralph-web/ralph-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/ralph-web/ralph-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/ralph-web/ralph-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/ralph-web/ralph-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/ralph-web/ralph-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ralph-web/ralph-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/ralph-web/ralph-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/home/runner/work/ralph-web/ralph-web/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_RZVNejKJBe from "/home/runner/work/ralph-web/ralph-web/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/ralph-web/ralph-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/ralph-web/ralph-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import colors_244lXBzhnM from "/home/runner/work/ralph-web/ralph-web/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/home/runner/work/ralph-web/ralph-web/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/ralph-web/ralph-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue3_google_map_1fnrZMtPlO from "/home/runner/work/ralph-web/ralph-web/plugins/vue3-google-map.js";
import chart_hiUwyzYZgo from "/home/runner/work/ralph-web/ralph-web/plugins/chart.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_client_RZVNejKJBe,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  vue3_google_map_1fnrZMtPlO,
  chart_hiUwyzYZgo
]