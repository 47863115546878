const route = useRoute()

// A function that determines whether the user can access the route
const metaPermission = () => {
    const localePath = useLocalePath()
    const authStore: any = useAuthStore()
    
    watch(() => route.meta, (newMeta, oldMeta) => {
        const userRole = authStore.user.role;

        // If roles meta is not defined, allow the user to use the current view
        if (!newMeta.permission || newMeta.permission.length === 0) {
            return;
        }

        // If user role is user and don't have permission to report a problem
        // Redirect to dashboard
        if(authStore.user.role == 'user' && !authStore.user.can_report_a_problem && (route.path == '/dashboard/reports' || route.path == '/dashboard/reports/add')){
            // Redirecting to dashboard
            return navigateTo(localePath('/dashboard'));
        }

        // If user's role is not in meta's roles array, redirect the user to the dashboard
        if (newMeta.permission.length > 0 && !newMeta.permission.includes(userRole)) {
            // Redirecting to dashboard
            return navigateTo(localePath('/dashboard'));
        } else {
            // Permission granted
        }
    }, {
        immediate: true,
    });
}

/* 
    --- Composable for user permissions based on role ---
    
    - Retrieve user roles
    - Determine permissions based on role

    / * TODO: Permissions Check * /
    / * Example of what is needed for checking permission of user instead of role * /
    
    function(permission){
        // We take permission prop for example: sport-parks.create
        // Check if user have this permission and return true or false
        const result = authStore.user.permissions.includes(permission)
        return result
    }
*/

// A function that determines whether the user can access the content
const roleHasPermission = (roles: Array<string> | string) => {
    const authStore: any = useAuthStore()
    const userRole: string = authStore.user.role

    return roles.includes(userRole)
}

export {
    roleHasPermission,
    metaPermission
};
  