import Vapor from 'laravel-vapor';

const runtimeConfig = useRuntimeConfig();

export const uploadImage = async (image: any) => {
    let file

    await Vapor.store(image, {
        options: {
            withCredentials: true
        },
        headers: {
            Accept: "application/json",
            "X-XSRF-TOKEN": csrfCookie(),
        },
        baseURL: runtimeConfig.public.publicUrl
        }).then((response: any) => {

        file = {
            key: response.key,
            extension: response.extension
        }
    });

    return file ?? null;
}